*,
*::before,
*::after {
    box-sizing: border-box;
}

html,
body,
#root {
    width: 100%;
    margin: 0;
    padding: 0;
}

.header {
    position: sticky;
    top: 0;
    left: 0;
    background-color: white;
}

.minMaxAvgContainer {
    display: flex;
    width: 100%;
    flex-direction: row;
}

.minMaxAvg {
    display: inline-block;
    width: calc(100% / 3);
    text-align: center;
}

.prices {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}