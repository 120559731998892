@media (orientation: landscape) {
    .portrait {
        display: none;
    }

    .landscape {
        display: block;
    }
}

@media (orientation: portrait) {
    .portrait {
        display: block;
    }

    .landscape {
        display: none;
    }
}